import React from 'react';

function Label({ children, ...props }) {
  return (
    <label className="Label block uppercase text-sm font-bold mb-2" {...props}>
      {children}
    </label>
  );
}

export default Label;
