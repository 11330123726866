import { useState, useEffect } from 'react';
import xlsx from 'xlsx';

function useExcelReader() {
  const [file, setFile] = useState();
  const [sheets, setSheets] = useState(undefined);

  useEffect(() => {
    if (!file) {
      setSheets(undefined);
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = xlsx.read(data, { type: 'array' });

      const sheets = {};

      Object.keys(workbook.Sheets).forEach((sheetName) => {
        sheets[sheetName.toLowerCase()] = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName]);
      });

      setSheets(sheets);
    };

    reader.readAsArrayBuffer(file);
  }, [file]);

  return [file, setFile, sheets];
}

export default useExcelReader;
