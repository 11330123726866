import createEmployees from './components/DataLoader/actions/createEmployees';
import createUsers from './components/DataLoader/actions/createUsers';
import fetchEmployeeActivations from './components/DataLoader/actions/fetchEmployeeActivations';
import createOffices from './components/DataLoader/actions/createOffices';
import createOfficeManagers from './components/DataLoader/actions/createOfficeManagers';
import createOfficeManagerOffices from './components/DataLoader/actions/createOfficeManagerOffices';
import createContacts from './components/DataLoader/actions/createContacts';
import createContactOffices from './components/DataLoader/actions/createContactOffices';
import fetchExistingEmployees from './components/DataLoader/actions/fetchExistingEmployees';
import fetchExistingUsers from './components/DataLoader/actions/fetchExistingUsers';
import fetchExistingOffices from './components/DataLoader/actions/fetchExistingOffices';
import fetchExistingOfficeManagers from './components/DataLoader/actions/fetchExistingOfficeManagers';
import fetchExistingOfficeManagerOffices from './components/DataLoader/actions/fetchExistingOfficeManagerOffices';
import fetchExistingContacts from './components/DataLoader/actions/fetchExistingContacts';
import fetchExistingContactOffices from './components/DataLoader/actions/fetchExistingContactOffices';

const skedifyLoadStructure = {
  existingEmployees: {
    resourceName: 'existingEmployees',
    isGlobal: true,
    action: fetchExistingEmployees,
    actionName: 'fetch',
  },
  employees: {
    resourceName: 'employees',
    key: 'user_external_id',
    action: createEmployees,
    actionName: 'import',
    dataSheetName: '1.users',
  },
  employeeActivations: {
    resourceName: 'employeeActivations',
    isGlobal: true,
    actionName: 'fetch',
    action: fetchEmployeeActivations,
  },
  existingUsers: {
    resourceName: 'existingUsers',
    isGlobal: true,
    action: fetchExistingUsers,
    actionName: 'fetch',
    dataSheetName: '1.users',
  },
  users: {
    key: 'user_external_id',
    action: createUsers,
    actionName: 'import',
    dataSheetName: '1.users',
  },
  existingOffices: {
    resourceName: 'existingOffices',
    isGlobal: true,
    action: fetchExistingOffices,
    actionName: 'fetch',
  },
  offices: {
    key: 'office_external_id',
    action: createOffices,
    actionName: 'import',
    dataSheetName: '2.offices',
  },
  existingOfficeManagers: {
    resourceName: 'existingOfficeManagers',
    isGlobal: true,
    action: fetchExistingOfficeManagers,
    actionName: 'fetch',
  },
  officeManagers: {
    key: 'user_external_id',
    action: createOfficeManagers,
    actionName: 'import',
    dataSheetName: '3.office managers',
  },
  existingOfficeManagerOffices: {
    resourceName: 'existingOfficeManagerOffices',
    isGlobal: true,
    action: fetchExistingOfficeManagerOffices,
    actionName: 'fetch',
    dataSheetName: '4.officemanager per office',
  },
  officeManagerOffices: {
    action: createOfficeManagerOffices,
    actionName: 'import',
    dataSheetName: '4.officemanager per office',
  },
  existingContacts: {
    resourceName: 'existingContacts',
    isGlobal: true,
    action: fetchExistingContacts,
    actionName: 'fetch',
    dataSheetName: '4.officemanager per office',
  },
  contacts: {
    key: 'user_external_id',
    action: createContacts,
    actionName: 'import',
    dataSheetName: '5.contact',
  },
  existingContactOffices: {
    resourceName: 'existingContactOffices',
    isGlobal: true,
    action: fetchExistingContactOffices,
    actionName: 'fetch',
    dataSheetName: '6.contact per office',
  },
  contactOffices: {
    action: createContactOffices,
    actionName: 'import',
    dataSheetName: '6.contact per office',
  },
};

export default skedifyLoadStructure;
