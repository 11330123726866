import React from 'react';
import c from '../utils/c.js';

function Input({ className, type, ...props }) {
  return (
    <input
      className={c(
        'block w-full bg-gray-200 rounded py-3 pl-3 pr-3 mb-4 focus:outline-none',
        className
      )}
      type={type || 'text'}
      {...props}
    />
  );
}

export default Input;
