import React, { useState, useEffect } from 'react';
import ReactJson from 'react-json-view';

import Header from '../shared/Header';
import Button from '../shared/Button';
import Center from '../shared/Center';
import Content from '../shared/Content';
import ButtonSpinner from '../shared/ButtonSpinner';
import { STATUS } from '../components/DataLoader/utils/constants';
import { StyledDialog, StyledDialogHeader } from '../shared/StyledDialog';

import c from '../utils/c';

function StateButton({ load, pause, resume, resourceGroup }) {
  switch (resourceGroup.status) {
    case STATUS.INIT:
      return (
        <Button onClick={() => load(resourceGroup.resourceName)}>{resourceGroup.actionName}</Button>
      );
    case STATUS.RESUMED:
      return (
        <ButtonSpinner onClick={() => pause(resourceGroup.resourceName)}>
          {resourceGroup.actionName}ing...
        </ButtonSpinner>
      );
    case STATUS.PAUSED:
      return <Button onClick={() => resume(resourceGroup.resourceName)}>Paused</Button>;
    case STATUS.FINISHED:
      return <Button disabled>Finished</Button>;
    default:
      throw new Error();
  }
}

function Import({ file, resourceGroups, load, pause, resume, importAll }) {
  const [isImportingAll, setIsImportingAll] = useState(false);
  const [resourceDialog, setResourceDialog] = useState(undefined);

  useEffect(() => setIsImportingAll(false), [file]);

  if (!file || !resourceGroups || resourceGroups.length === 0) {
    return null;
  }

  const isCompletelyFinished = Object.values(resourceGroups).every(
    (resourceGroup) => resourceGroup.status === STATUS.FINISHED
  );

  return (
    <div className="Import">
      <Content>
        <Header>Import</Header>
        <Center className="mb-8">
          <Button
            disabled={isImportingAll || isCompletelyFinished}
            onClick={() => {
              setIsImportingAll(true);
              importAll();
            }}
          >
            {isCompletelyFinished && 'Finished'}
            {!isCompletelyFinished && isImportingAll && 'Importing...'}
            {!isCompletelyFinished && !isImportingAll && 'Import all'}
          </Button>
        </Center>
        {Object.values(resourceGroups).map((resourceGroup) => {
          const hasErrors = resourceGroup.errors.length > 0;

          return (
            <div className="flex justify-between mt-4 mb-8" key={resourceGroup.resourceName}>
              <div className="truncate" style={{ width: '210px' }}>
                <span className="font-semibold text-lg">{resourceGroup.resourceName}</span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold text-lg">{resourceGroup.total}</span>
                <span className="text-gray-900 -mt-1">Total</span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold text-lg">{resourceGroup.success}</span>
                <span className="text-gray-900 -mt-1">Success</span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold text-lg">{resourceGroup.skip}</span>
                <span className="text-gray-900 -mt-1">Skip</span>
              </div>
              <div
                className={c('flex flex-col', hasErrors && 'text-blue-900 cursor-pointer')}
                onClick={() => setResourceDialog(resourceGroup)}
              >
                <span className="font-semibold text-lg">{resourceGroup.errors.length}</span>
                <span className={c('-mt-1', !hasErrors && 'text-gray-900')}>Errors</span>
              </div>
              <div style={{ width: '150px' }} className="text-right">
                <StateButton
                  resourceGroup={resourceGroup}
                  load={load}
                  pause={pause}
                  resume={resume}
                />
              </div>
            </div>
          );
        })}
      </Content>
      <StyledDialog
        isOpen={!!resourceDialog}
        onDismiss={() => setResourceDialog(undefined)}
        aria-label="Dialog with errors regarding to the failed api calls"
      >
        {resourceDialog && (
          <>
            <StyledDialogHeader close={() => setResourceDialog(undefined)}>
              <Header>
                Errors<div className="text-sm">{resourceDialog.resourceName}</div>
              </Header>
            </StyledDialogHeader>

            {resourceDialog.errors.map(({ error, data } = {}, i) => {
              let errorObject = { message: 'We could not find an error message' };

              if (error) {
                errorObject = error.status ? error : { message: error.message };
              }

              return (
                <React.Fragment key={i}>
                  <div className="font-semibold mb-4">Error #{i + 1}</div>
                  <div className="flex mb-12">
                    <div className="w-1/2 overflow-auto">
                      <ReactJson
                        src={data || {}}
                        name={false}
                        indentWidth={2}
                        enableClipboard={false}
                        displayObjectSize={false}
                      />
                    </div>
                    <div className="w-1/2">
                      <ReactJson
                        src={errorObject}
                        name={false}
                        indentWidth={2}
                        enableClipboard={false}
                        displayObjectSize={false}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </>
        )}
      </StyledDialog>
    </div>
  );
}

export default Import;
