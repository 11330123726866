import Skedify from 'skedify-sdk';

export const IDPS = {
  public_client: ['client_id'],
  client_credentials: ['client_id', 'client_secret'],
};

function createSDK({ type, ...rest }) {
  return new Skedify({
    auth_provider: Skedify.createAuthProviderString(type, rest),
    locale: 'nl-BE',
  });
}

export default createSDK;
