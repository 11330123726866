export function trim(input) {
  return input.trim();
}

export function lowercase(input) {
  return input.toLowerCase();
}

export function uppercase(input) {
  return input.toUpperCase();
}

