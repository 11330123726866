import { useEffect } from 'react';

function beforeUnload(e) {
  // Cancel the event
  e.preventDefault();

  // Chrome requires returnValue to be set
  e.returnValue = '';
}

function useWarnBeforeUnload(enabled = true) {
  useEffect(() => {
    enabled
      ? window.addEventListener('beforeunload', beforeUnload)
      : window.removeEventListener('beforeunload', beforeUnload);

    return () => window.removeEventListener('beforeunload', beforeUnload);
  }, [enabled]);
}

export default useWarnBeforeUnload;
