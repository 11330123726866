import { useState } from 'react';

function usePersistentState(keyName, initialValue) {
  const [value, setValue] = useState(() => {
    const item = window.localStorage.getItem(keyName);
    return item ? JSON.parse(item) : initialValue;
  });

  function setPersistentValue(value) {
    setValue(value);
    window.localStorage.setItem(keyName, JSON.stringify(value));
  }

  return [value, setPersistentValue];
}

export default usePersistentState;
