import React, { Fragment, useState, useRef } from 'react';
import pick from 'lodash.pick';
import Content from '../shared/Content';
import Header from '../shared/Header';
import SubHeader from '../shared/SubHeader';
import Label from '../shared/Label';
import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import { IDPS } from '../sdk';

const types = Object.keys(IDPS);

function Settings({ credentials, setCredentials }) {
  const [draftCredentials, setDraftCredentials] = useState(credentials);
  const [dirty, setDirty] = useState(true);
  const form = useRef();

  function setValue(e) {
    setDraftCredentials({
      ...draftCredentials,
      [e.target.name]: e.target.value,
    });

    setDirty(true);
  }

  const fields = IDPS[draftCredentials.type];

  function save() {
    if (form.current.reportValidity()) {
      setCredentials(pick(draftCredentials, 'type', 'realm', ...fields));
      setDirty(false);
    }
  }

  return (
    <div className="Settings">
      <Content>
        <Header>Settings</Header>
        <SubHeader>API Authentication</SubHeader>
        <form className="mb-4" ref={form}>
          <Label htmlFor="type">Type</Label>
          <Select
            id="type"
            name="type"
            options={types}
            defaultValue={draftCredentials.type}
            onChange={setValue}
          />

          <Label htmlFor="realm">Realm</Label>
          <Input
            id="realm"
            name="realm"
            type="url"
            placeholder="https://api.skedify.com/enterprises/development"
            defaultValue={draftCredentials.realm}
            onChange={setValue}
            required
          />

          {fields.map((field) => (
            <Fragment key={field}>
              <Label htmlFor={field}>{field}</Label>
              <Input
                id={field}
                name={field}
                defaultValue={draftCredentials[field]}
                onChange={setValue}
                required
              />
            </Fragment>
          ))}

          <Button primary onClick={save} type="button" disabled={!dirty}>
            {dirty ? 'Wijzig' : 'Saved!'}
          </Button>
        </form>
      </Content>
    </div>
  );
}

Settings.defaultProps = {
  credentials: { type: types[0] },
};

export default Settings;
