import React from 'react';

function Content({ children }) {
  return (
    <div className="Content flex justify-center mt-6 mb-6">
      <div className="bg-white w-5/6 rounded shadow p-6">{children}</div>
    </div>
  );
}

export default Content;
