import React from 'react';
import Center from '../shared/Center';
import Button from '../shared/Button';
import FileInput from '../shared/FileInput';

function ChooseFile({ setFile }) {
  return (
    <Center>
      <FileInput onFile={setFile}>
        <Button primary>Choose a file</Button>
      </FileInput>
    </Center>
  );
}

export default ChooseFile;
