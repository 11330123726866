import * as validationRules from './rules';

const pipe = (fns) => (input) => fns.reduce((output, fn) => fn(output), input);

class WorkbookValidator {
  constructor(workbook, structure) {
    this.workbook = workbook;
    this.structure = structure;

    this.cachedColumns = {};
  }

  transformSheet(sheet, fields) {
    return sheet.map((row) => {
      const enhancedRow = { ...row };

      fields.forEach((field) => {
        if (field.transformers && enhancedRow[field.key]) {
          enhancedRow[field.key] = pipe(field.transformers)(enhancedRow[field.key]);
        }
      });

      return enhancedRow;
    });
  }

  validateCell(cell, { rules = [], key, displayName }, sheet, sheetName) {
    let validationError;

    const isRequired = rules.find((rule) => rule === 'required');

    // If it is not required, and the value is empty we will not test the other validations
    if (!isRequired && !validationRules.required(cell)) {
      return validationError;
    }

    rules.every((rule) => {
      const [ruleName, ...args] = rule.split(':');

      if (validationRules[ruleName](cell, sheet, sheetName, key, this, ...args)) {
        return true;
      }

      validationError = `${displayName || key} - ${rule}`;

      return false;
    });

    return validationError;
  }

  validateSheet(sheet, sheetName, fields) {
    return sheet.map((row) => {
      const validationErrors = fields.reduce((validationErrors, field) => {
        const error = this.validateCell(row[field.key], field, sheet, sheetName);

        if (error) {
          validationErrors.push(error);
        }

        return validationErrors;
      }, []);

      return validationErrors.length > 0 ? { ...row, validationErrors } : row;
    });
  }

  column(sheetName, columnName) {
    const name = sheetName + columnName;

    if (this.cachedColumns[name]) {
      return this.cachedColumns[name];
    }

    this.cachedColumns[name] = this.workbook[sheetName].map((row) => row[columnName]);

    return this.cachedColumns[name];
  }

  validate() {
    if (!this.workbook) {
      return;
    }

    Object.keys(this.structure).forEach((sheetName) => {
      const { fields } = this.structure[sheetName];

      // Transform the sheet first before applying the validations.
      // We can't do it while we are validating because the validation
      // can relay on other values of other rows that has not yet been transformed.
      this.workbook[sheetName] = this.transformSheet(this.workbook[sheetName], fields);

      this.workbook[sheetName] = this.validateSheet(this.workbook[sheetName], sheetName, fields);
    });

    return this.workbook;
  }
}

export default WorkbookValidator;
