import React from 'react';
import { FixedSizeList as List } from 'react-window';

function ExcelTableViewer({ columns, data }) {
  return (
    <>
      <div className="flex border-b">
        {columns.map((column) => (
          <div key={column.key} className="text-left p-2 truncate" style={{ width: column.width }}>
            {column.displayName === undefined ? column.key : column.displayName}
          </div>
        ))}
      </div>
      <List height={500} width={'100%'} itemCount={data.length} itemSize={41} itemData={data}>
        {({ index, style }) => {
          return (
            <div key={index} className="flex border-b" style={style}>
              {columns.map(({ key, width, render: RenderComponent }) => (
                <div key={`${index}-${key}`} style={{ width }} className="p-2 truncate">
                  {RenderComponent ? (
                    <RenderComponent data={data[index][key]} row={data[index]} />
                  ) : (
                    data[index][key]
                  )}
                </div>
              ))}
            </div>
          );
        }}
      </List>
    </>
  );
}

export default ExcelTableViewer;
