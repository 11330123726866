import mapData from '../utils/mapData';
import isExistingContact from '../utils/isExistingContact';
import errorHandler from '../utils/errorHandler';

const mapping = {
  employee_id: (context, { user_external_id }) => {
    const employee =
      (context.employees && context.employees[user_external_id]) ||
      (context.existingEmployees && context.existingEmployees[user_external_id]);
    return employee && employee.id;
  },
};

function createContacts(SDK, data, key) {
  return data.map((contact) => {
    return (context) => {
      const contactData = mapData(context, contact, mapping);

      if (isExistingContact(context, contact.user_external_id)) {
        return Promise.resolve();
      }

      return SDK.contacts()
        .new(contactData)
        .then((resource) => resource.create())
        .then((response) => ({
          key: contact[key],
          resource: response.data,
        }))
        .catch(errorHandler(contactData));
    };
  });
}

export default createContacts;
