import mapData from '../utils/mapData';
import isExistingContactOffice from '../utils/isExistingContactOffice';
import errorHandler from '../utils/errorHandler';

const mapping = {
  contact_id: (context, { user_external_id }) => {
    const contact =
      (context.contacts && context.contacts[user_external_id]) ||
      (context.existingContacts && context.existingContacts[user_external_id]);
    return contact && contact.id;
  },
  office_id: (context, { office_external_id }) => {
    const office =
      (context.offices && context.offices[office_external_id]) ||
      (context.existingOffices && context.existingOffices[office_external_id]);
    return office && office.id;
  },
};

function createContactOffices(SDK, data) {
  return data.map((contactOffice) => {
    return (context) => {
      const { office_id, contact_id } = mapData(context, contactOffice, mapping);

      if (
        isExistingContactOffice(
          context,
          contactOffice.user_external_id,
          contactOffice.office_external_id
        )
      ) {
        return Promise.resolve();
      }

      return SDK.contactOffices()
        .new({
          contact_id,
          office_id,
        })
        .then((resource) => resource.create())
        .then((response) => ({
          key: undefined,
          resource: undefined,
        }))
        .catch(errorHandler({ contact_id, office_id }));
    };
  });
}

export default createContactOffices;
