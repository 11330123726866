import React from 'react';
import c from '../utils/c';
import Spinner from './Spinner';
import Button from './Button';

function ButtonSpinner({ children, className, ...props }) {
  return (
    <Button className={c(className, 'inline-flex items-center')} {...props}>
      <Spinner className="mr-2" />
      {children}
    </Button>
  );
}

export default ButtonSpinner;
