import mapData from '../utils/mapData';
import errorHandler from '../utils/errorHandler';

const mapping = {
  title: 'office_title',
  external_id: 'office_external_id',
  email: 'office_email',
  phone_number: 'office_phone',
  location: {
    street_1: 'office_street1',
    postal_code: 'office_zip',
    city: 'office_city',
    country: 'office_country_code',
    geolocation: 'office_geolocation',
  },
};

function createOffices(SDK, data, key) {
  return data.map((office) => {
    return (context, { isExistingOffice }) => {
      const officeData = mapData(context, office, mapping);

      if (isExistingOffice(officeData.external_id)) {
        return Promise.resolve();
      }

      return SDK.offices()
        .new(officeData)
        .then((resource) => resource.create())
        .then((response) => ({
          key: office[key],
          resource: response.data,
        }))
        .catch(errorHandler(officeData));
    };
  });
}

export default createOffices;
