function errorHandler(data) {
  return (error) => {
    // eslint-disable-next-line no-throw-literal
    throw {
      error,
      data,
    };
  };
}

export default errorHandler;
