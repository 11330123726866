import errorHandler from '../utils/errorHandler';

function fetchExistingUsers(SDK) {
  return [
    (context) =>
      SDK.users()
        .then(({ data }) =>
          data.map((user) => {
            const employee = Object.values(context.existingEmployees).find(
              (employee) => employee.user_id === user.id
            );
            const user_external_id = employee && employee.external_id;

            return {
              key: user_external_id,
              resource: user,
            };
          })
        )
        .catch(errorHandler()),
  ];
}

export default fetchExistingUsers;
