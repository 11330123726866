import React from 'react';
import c from '../utils/c';

function Button({ primary, disabled, children, className, ...props }) {
  return (
    <button
      className={c(
        'inline-block uppercase px-3 py-1 rounded-full border',
        disabled && 'text-gray-900',
        !disabled && primary && 'bg-blue-500 text-white border-transparent hover:bg-blue-400',
        !disabled &&
          !primary &&
          'border-blue-700 text-blue-900 hover:border-blue-500 hover:text-blue-500',
        className
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
}

export default Button;
