import errorHandler from '../utils/errorHandler';

function fetchEmployeeActivations(SDK) {
  return [
    () =>
      SDK.employeeActivations()
        .then(({ data }) =>
          data.map((employeeActivation) => ({
            key: employeeActivation.employee_id,
            resource: employeeActivation,
          }))
        )
        .catch(errorHandler()),
  ];
}

export default fetchEmployeeActivations;
