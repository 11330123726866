import React from 'react';
import Stepbar from '../shared/Stepbar';
import Step from '../shared/Step';
import ExcelTableViewer from '../shared/ExcelTableViewer';

function Excel({ file, filteredSheets }) {
  if (!file || !filteredSheets) {
    return null;
  }

  return (
    <Stepbar className="mt-8">
      {filteredSheets.map((sheet) => {
        const showBadge = sheet.data.find((row) => row.validationErrors);

        return (
          <Step stepHeader={sheet.displayName} key={sheet.key} showBadge={showBadge}>
            <div className="bg-white mt-4 mb-12 rounded shadow">
              <ExcelTableViewer columns={sheet.columns} data={sheet.data} />
            </div>
          </Step>
        );
      })}
    </Stepbar>
  );
}

export default Excel;
