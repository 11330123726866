import React from 'react';
import { Dialog } from '@reach/dialog';
import X from './Icons/X';

import '@reach/dialog/styles.css';

export function StyledDialogHeader({ children, close }) {
  return (
    <div className="flex justify-between">
      {children}
      <X onClick={close} className="cursor-pointer" />
    </div>
  );
}

export function StyledDialog({
  isOpen,
  onDismiss,
  close,
  children,
  'aria-label': ariaLabel = 'A Dialog',
}) {
  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss} aria-label={ariaLabel}>
      {children}
    </Dialog>
  );
}

export default StyledDialog;
