import React from 'react';
import ReachTooltip from '@reach/tooltip';

import '@reach/tooltip/styles.css';

function Tooltip({ label, children }) {
  return <ReachTooltip label={label}>{children}</ReachTooltip>;
}

export default Tooltip;
