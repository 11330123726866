import errorHandler from '../utils/errorHandler';

function fetchExistingOfficeManagers(SDK, data) {
  return [
    () =>
      SDK.officeManagers()
        .then(({ data }) =>
          data.map((office_manager) => ({
            key: office_manager.external_id,
            resource: office_manager,
          }))
        )
        .catch(errorHandler()),
  ];
}

export default fetchExistingOfficeManagers;
