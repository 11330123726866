import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import isGoogleChrome from './utils/isGoogleChrome';
import UnsupportedBrowser from './shared/UnsupportedBrowser';

import './theme/index.css';

const Component = isGoogleChrome ? App : UnsupportedBrowser;

ReactDOM.render(<Component />, document.getElementById('root'));
