import React from 'react';

function LinkButton({ children, ...props }) {
  return (
    <button
      className="text-blue-800 border-b border-transparent pb-px hover:border-blue-800"
      {...props}
    >
      {children}
    </button>
  );
}

export default LinkButton;
