import mapData from '../utils/mapData';
import isExistingOfficeManagerOffice from '../utils/isExistingOfficeManagerOffice';
import errorHandler from '../utils/errorHandler';

const mapping = {
  office_id: (context, { office_external_id }) => {
    const office =
      (context.offices && context.offices[office_external_id]) ||
      (context.existingOffices && context.existingOffices[office_external_id]);
    return office && office.id;
  },
  office_manager_id: (context, { user_external_id }) => {
    const officeManager =
      (context.officeManagers && context.officeManagers[user_external_id]) ||
      (context.existingOfficeManagers && context.existingOfficeManagers[user_external_id]);
    return officeManager && officeManager.id;
  },
};

function createOfficeManagerOffices(SDK, data) {
  return data.map((officeManagerOffice) => {
    return (context) => {
      const { office_id, office_manager_id } = mapData(context, officeManagerOffice, mapping);

      if (
        isExistingOfficeManagerOffice(
          context,
          officeManagerOffice.user_external_id,
          officeManagerOffice.office_external_id
        )
      ) {
        return Promise.resolve();
      }

      return SDK.officeManagerOffices()
        .new({
          office_manager_id,
          office_id,
        })
        .then((resource) => resource.create())
        .then((response) => ({
          key: undefined,
          resource: undefined,
        }))
        .catch(errorHandler({ office_manager_id, office_id }));
    };
  });
}

export default createOfficeManagerOffices;
