import mapData from '../utils/mapData';
import isExistingEmployee from '../utils/isExistingEmployee';
import errorHandler from '../utils/errorHandler';

const mapping = {
  email: 'user_email',
  employee_email: 'user_employee_email', // not a field
  external_id: 'user_external_id',
};

function createEmployees(SDK, data, key) {
  return data.map((employee) => {
    return (context) => {
      const employeeData = mapData(context, employee, mapping);

      if (isExistingEmployee(context, employeeData.external_id)) {
        return Promise.resolve();
      }

      const data = {
        external_id: employeeData.external_id,
        email: employeeData.employee_email ?? employeeData.email,
        suppress_activation_email: true,
      };

      return SDK.employees()
        .new(data)
        .then((resource) => resource.create())
        .then((response) => ({
          key: employee[key],
          resource: response.data,
        }))
        .catch(errorHandler(data));
    };
  });
}

export default createEmployees;
