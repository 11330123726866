import errorHandler from '../utils/errorHandler';

function fetchExistingOfficeManagerOffices(SDK) {
  return [
    (context) =>
      SDK.officeManagerOffices()
        .then(({ data }) => {
          return data.map((office_manager_office) => {
            const office_manager = Object.values(context.existingOfficeManagers).find(
              (office_manager) =>
                office_manager.roles.office_manager.id === office_manager_office.office_manager_id
            );
            const user_external_id = office_manager && office_manager.external_id;

            const office = Object.values(context.existingOffices).find(
              (office) => office.id === office_manager_office.office_id
            );
            const office_external_id = office && office.external_id;

            return {
              key: `${user_external_id}-${office_external_id}`,
              resource: office_manager_office,
            };
          });
        })
        .catch(errorHandler()),
  ];
}

export default fetchExistingOfficeManagerOffices;
