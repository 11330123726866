import React from 'react';
import Tooltip from '../../shared/Tooltip';

function ValidationColumn({ data, row }) {
  return data && data.length > 0 ? (
    <Tooltip label={data.join(', ')}>
      <div className="rounded-full h-6 w-6 flex items-center justify-center border-2 border-red-400 text-red-400 select-none">
        <span>!</span>
      </div>
    </Tooltip>
  ) : null;
}

export default ValidationColumn;
