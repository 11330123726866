import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Logo } from './Logo.svg';
import Button from '../shared/Button';
import { EXCEL_FILTER } from '../constants';

function StyledLink({ ...props }) {
  return (
    <NavLink
      {...props}
      className="inline-block uppercase px-3 py-1 border-b-2 mr-4 border-transparent"
      activeClassName="border-blue-500"
    />
  );
}

function Heading({ toggleFilter, toggleImport, filter }) {
  return (
    <div className="Heading h-20 flex bg-white items-center justify-center">
      <div className="w-5/6 flex justify-between">
        <div className="flex items-end">
          <Logo className="h-8" />
          <span className="ml-1 text-gray-600 text-xs">v{process.env.REACT_APP_VERSION}</span>
        </div>
        <div>
          <StyledLink exact to="/">
            Excel
          </StyledLink>
          <StyledLink to="import">Import</StyledLink>
          <StyledLink to="settings">Settings</StyledLink>
          <Button onClick={toggleFilter} primary={filter === EXCEL_FILTER.ONLY_ERRORS}>
            Only errors
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Heading;
