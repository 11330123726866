const passwordChars =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!"#$%&()*+,-./:;<=>?@[\\]^_`{|}~';

const passwordCharsArray = passwordChars.split('');

function generatePassword(length) {
  return Array.from(window.crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => passwordCharsArray[x % passwordCharsArray.length])
    .join('');
}

export default generatePassword;
