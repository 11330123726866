import mapData from '../utils/mapData';
import isExistingOfficeManager from '../utils/isExistingOfficeManager';
import errorHandler from '../utils/errorHandler';

const mapping = {
  employee_id: (context, { user_external_id }) => {
    const employee =
      (context.employees && context.employees[user_external_id]) ||
      (context.existingEmployees && context.existingEmployees[user_external_id]);
    return employee && employee.id;
  },
};

function createOfficeManagers(SDK, data, key) {
  return data.map((officeManager) => {
    return (context) => {
      if (isExistingOfficeManager(context, officeManager.user_external_id)) {
        return Promise.resolve();
      }

      const officeManagerData = mapData(context, officeManager, mapping);

      return SDK.officeManagers()
        .new(officeManagerData)
        .then((resource) => resource.create())
        .then((response) => ({
          key: officeManager[key],
          resource: response.data,
        }))
        .catch(errorHandler(officeManagerData));
    };
  });
}

export default createOfficeManagers;
