import errorHandler from '../utils/errorHandler';

function fetchExistingContacts(SDK) {
  return [
    () =>
      SDK.contacts()
        .then(({ data }) =>
          data.map((contact) => ({
            key: contact.external_id,
            resource: contact,
          }))
        )
        .catch(errorHandler()),
  ];
}

export default fetchExistingContacts;
