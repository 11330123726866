import React, { useRef } from 'react';

function FileInput({ children, onFile = () => {} }) {
  const inputElement = useRef(null);

  function openDialog() {
    inputElement.current.click();
  }

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          onClick: openDialog,
        })
      )}
      <input
        className="hidden"
        type="file"
        ref={inputElement}
        data-testid="FileInput--hidden-file-input"
        onChange={(e) => onFile(e.target.files[0])}
      />
    </>
  );
}

export default FileInput;
