import { useReducer, useState, useEffect, useMemo } from 'react';
import produce from 'immer';

import { STATUS } from './utils/constants';
import skedifyLoadStructure from '../../skedifyLoadStructure';
import useWarnBeforeUnload from '../../shared/hooks/useWarnBeforeUnload';
import isExistingOffice from './utils/isExistingOffice';

function reducer(state, action) {
  switch (action.type) {
    case 'initialize':
      return initialize(state, action.sheets);
    case 'load':
      return load(state, action.resourceName, action.SDK);
    case 'pause':
      return pause(state, action.resourceName);
    case 'resume':
      return resume(state, action.resourceName);
    case 'finish':
      return finish(state, action.resourceName);
    case 'batch':
      return batch(state, action.dispatch);
    case 'setResource':
      return setResource(state, action.resourceName, action.resource);
    case 'setError':
      return setError(state, action.resourceName, action.error);
    default:
      throw new Error();
  }
}

function initialize(state, sheets) {
  const resourceGroups = {};

  Object.entries(skedifyLoadStructure).forEach(([resourceName, resourceGroup]) => {
    const sheet = sheets.find((sheet) => sheet.key === resourceGroup.dataSheetName) || {};

    resourceGroups[resourceName] = {
      resourceName,
      ...resourceGroup,
      total: resourceGroup.isGlobal ? 1 : sheet.data.length,
      success: 0,
      skip: 0,
      errors: [],
      data: sheet.data,
      status: STATUS.INIT,
    };
  });

  return {
    ...state,
    batches: {},
    context: {},
    resourceGroups,
  };
}

/**
 * Creates all actions for a resource group
 */
function load(state, resourceName, SDK) {
  const { action, data, key, mapping } = state.resourceGroups[resourceName];
  const batch = action(SDK, data, key, mapping);

  return produce(state, (draft) => {
    draft.resourceGroups[resourceName].status = STATUS.RESUMED;
    draft.batches[resourceName] = batch;
  });
}

/**
 * Pauses the current resource group
 */
function pause(state, resourceName) {
  return produce(state, (draft) => {
    draft.resourceGroups[resourceName].status = STATUS.PAUSED;
  });
}

/**
 * Resumes the current resource group
 */
function resume(state, resourceName) {
  return produce(state, (draft) => {
    draft.resourceGroups[resourceName].status = STATUS.RESUMED;
  });
}

/**
 * Finish the current resource group
 */
function finish(state, resourceName) {
  return produce(state, (draft) => {
    draft.resourceGroups[resourceName].status = STATUS.FINISHED;
  });
}

/**
 * Searches and process the next batch
 */
function batch(state, dispatch) {
  return produce(state, (draft) => {
    // Find a batch that has at least one action remaining
    const batchEntry = Object.entries(draft.batches).find(
      ([resourceName]) => draft.resourceGroups[resourceName].status === STATUS.RESUMED
    );

    // If there is no batch, stop processing.
    if (!batchEntry) {
      return;
    }

    const [resourceName, batch] = batchEntry;

    if (batch.length === 0) {
      draft.resourceGroups[resourceName].status = STATUS.FINISHED;
      dispatch({ type: 'batch', dispatch });
      return;
    }

    // Take 20 actions from the complete batch to be able to pause the processing.
    const nextBatch = batch.splice(0, 20);

    const lengthRemainingBatch = batch.length;

    let skipped = 0;

    const promises = nextBatch.map((action) => {
      return action(state.context, {
        isExistingOffice: isExistingOffice(state.context),
      })
        .then((resource) => {
          // If resource is undefined, the action has been skipped
          if (resource === undefined) {
            skipped++;
          }

          dispatch({
            type: 'setResource',
            resourceName,
            resource,
          });
        })
        .catch((error) =>
          dispatch({
            type: 'setError',
            resourceName,
            error,
          })
        );
    });

    Promise.all(promises).then(async () => {
      if (skipped === 20) {
        await new Promise((resolve) => setTimeout(resolve, 1));
      }

      dispatch({ type: 'batch', dispatch });

      if (lengthRemainingBatch === 0) {
        dispatch({ type: 'finish', resourceName });
      }
    });
  });
}

/**
 * Increments the success counter, and saves the resource in the context
 */
function setResource(state, resourceName, resource) {
  return produce(state, (draft) => {
    const resourceGroup = draft.resourceGroups[resourceName];

    // If resource is undefined, the action has been skipped
    if (!resource) {
      resourceGroup.skip++;
      return;
    }

    resourceGroup.success++;

    if (!draft.context[resourceName]) {
      draft.context[resourceName] = {};
    }

    let resources = Array.isArray(resource) ? resource : [resource];

    resources.forEach(({ resource, key }) => {
      draft.context[resourceName][key] = resource;
    });
  });
}

/**
 * Increments the error counter
 */
function setError(state, resourceName, error) {
  return produce(state, (draft) => {
    const resourceGroup = draft.resourceGroups[resourceName];

    resourceGroup.errors.push(error);
  });
}

function findResumedBatches(batches = {}, resourceGroups = {}) {
  const remainingBatches = Object.entries(batches)
    .filter(([resourceName, batch]) => {
      return batch.length > 0 && resourceGroups[resourceName].status === STATUS.RESUMED;
    })
    .map(([resourceName]) => resourceName);

  return remainingBatches;
}

function DataLoader({ SDK, sheets, children }) {
  const [state, dispatch] = useReducer(reducer, {});
  const [currentImport, setCurrentImport] = useState(-1);

  useEffect(() => {
    setCurrentImport(-1);
    sheets && sheets.length && dispatch({ type: 'initialize', sheets });
  }, [sheets]);

  const resumedBatches = useMemo(() => findResumedBatches(state.batches, state.resourceGroups), [
    state.batches,
    state.resourceGroups,
  ]);

  const hasResumedBatches = resumedBatches.length > 0;

  useEffect(() => {
    hasResumedBatches && dispatch({ type: 'batch', dispatch });
  }, [hasResumedBatches]);

  useWarnBeforeUnload(hasResumedBatches);

  if (!state.resourceGroups) {
    return children();
  }

  const resourceGroups = Object.values(state.resourceGroups);

  if (currentImport >= 0 && currentImport + 1 < resourceGroups.length) {
    if (resourceGroups[currentImport].status === STATUS.FINISHED) {
      setCurrentImport(currentImport + 1);

      if (resourceGroups[currentImport + 1].status === STATUS.INIT) {
        dispatch({
          type: 'load',
          resourceName: resourceGroups[currentImport + 1].resourceName,
          SDK,
        });
      }
    }
  }

  function importAll() {
    setCurrentImport(0);

    if (resourceGroups[0].status === STATUS.INIT) {
      dispatch({ type: 'load', resourceName: resourceGroups[0].resourceName, SDK });
    }
  }

  return children(
    state.resourceGroups,
    (resourceName) => dispatch({ type: 'load', resourceName, SDK }),
    (resourceName) => dispatch({ type: 'pause', resourceName }),
    (resourceName) => dispatch({ type: 'resume', resourceName }),
    importAll
  );
}

export default DataLoader;
