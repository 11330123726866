import React, { useState } from 'react';
import c from '../utils/c';

function Stepbar({ children, className, ...props }) {
  const headers = React.Children.map(children, (child) => ({
    header: child.props.stepHeader,
    showBadge: child.props.showBadge,
  }));

  const [activeItem, setActiveItem] = useState(headers[0].header);

  return (
    <>
      <div className={c('Stepbar flex justify-between mx-6', className)} {...props}>
        {headers.map(({ header, showBadge }, step) => (
          <button
            key={header}
            onClick={() => setActiveItem(header)}
            className="flex flex-col items-center"
          >
            <div
              className={c(
                'flex items-center justify-center w-6 h-6 text-xs font-semibold relative',
                'rounded-full border',
                header === activeItem && 'border-none bg-blue-700 text-white',
                header !== activeItem && 'text-gray-500'
              )}
            >
              {showBadge && (
                <div
                  className={c(
                    'absolute top-0 right-0 -mt-2 -mr-2',
                    'flex items-center justify-center w-4 h-4 text-xs font-semibold',
                    'rounded-full border border-transparent',
                    'bg-red-400 text-white'
                  )}
                  aria-label={`There is a warning badge on the step ${header}`}
                >
                  !
                </div>
              )}
              {step + 1}
            </div>
            <div className={c('mt-1', header === activeItem ? 'text-blue-700' : 'text-gray-500')}>
              {header}
            </div>
          </button>
        ))}
      </div>
      {React.Children.map(children, (child) =>
        child.props.stepHeader === activeItem ? child : null
      )}
    </>
  );
}

export default Stepbar;
