import { lowercase, trim } from './utils/transformers';

const skedifyExcelStructure = {
  '1.users': {
    displayName: 'Users',
    fields: [
      {
        key: 'user_last_name',
        displayName: 'Last name',
        width: '14%',
        rules: ['required', 'max:64'],
      },
      {
        key: 'user_first_name',
        displayName: 'First name',
        width: '14%',
        rules: ['max:64'],
      },
      {
        key: 'user_email',
        displayName: 'User email',
        width: '20%',
        rules: ['required', 'email', 'unique', 'max:128'],
        transformers: [trim, lowercase],
      },
      {
        key: 'user_employee_email',
        displayName: 'Employee email',
        width: '20%',
        rules: ['email', 'unique', 'max:128'],
        transformers: [trim, lowercase],
      },
      {
        key: 'user_external_id',
        displayName: 'External ID',
        width: '10%',
        rules: ['required', 'unique', 'max:64'],
      },
      {
        key: 'user_phone',
        displayName: 'Phone',
        width: '16%',
        rules: ['max:64'],
      },
      {
        key: 'user_language',
        displayName: 'Language',
        width: '6%',
        rules: ['required', 'language'],
        transformers: [lowercase],
      },
    ],
  },
  '2.offices': {
    displayName: 'Offices',
    fields: [
      {
        key: 'office_title',
        displayName: 'Title',
        width: '15%',
        rules: ['required', 'max:96'],
      },
      {
        key: 'office_street1',
        displayName: 'Street',
        width: '10%',
        rules: ['required', 'max:255'],
      },
      {
        key: 'office_zip',
        displayName: 'ZIP',
        width: '5%',
        rules: ['required', 'max:12'],
      },
      {
        key: 'office_city',
        displayName: 'City',
        width: '10%',
        rules: ['required', 'max:64'],
      },
      {
        key: 'office_country_code',
        displayName: 'Country code',
        width: '5%',
        rules: ['required', 'countrycode'],
      },
      {
        key: 'office_geolocation',
        displayName: 'Geolocation',
        width: '13%',
        rules: ['required', 'max:64', 'geolocation'],
      },
      {
        key: 'office_external_id',
        displayName: 'External ID',
        width: '7%',
        rules: ['required', 'unique', 'max:64'],
      },
      {
        key: 'office_email',
        displayName: 'Email',
        width: '15%',
        rules: ['email', 'max:128'],
        transformers: [trim, lowercase],
      },
      {
        key: 'office_phone',
        displayName: 'Phone',
        width: '10%',
        rules: ['max:64'],
      },
    ],
  },
  '3.office managers': {
    displayName: 'Office managers',
    fields: [
      {
        key: 'user_last_name',
        displayName: 'Last name',
        width: '25%',
        rules: ['max:64'],
      },
      {
        key: 'user_first_name',
        displayName: 'First name',
        width: '25%',
        rules: ['max:64'],
      },
      {
        key: 'user_email',
        displayName: 'Email',
        width: '25%',
        rules: ['email', 'unique', 'max:128'],
        transformers: [trim, lowercase],
      },
      {
        key: 'user_external_id',
        displayName: 'External ID',
        width: '25%',
        rules: ['required', 'unique', 'max:64', 'exists:1.users,user_external_id'],
      },
    ],
  },
  '4.officemanager per office': {
    displayName: 'Officemanager per office',
    fields: [
      {
        key: 'user_external_id',
        displayName: 'User external ID',
        width: '33.33%',
        rules: ['required', 'max:64', 'exists:1.users,user_external_id'],
      },
      {
        key: 'office_external_id',
        displayName: 'Office external ID',
        width: '33.33%',
        rules: ['required', 'max:64', 'exists:2.offices,office_external_id'],
      },
      {
        key: 'office_title',
        displayName: 'Office title',
        width: '33.33%',
      },
    ],
  },
  '5.contact': {
    displayName: 'Contact',
    fields: [
      {
        key: 'user_last_name',
        displayName: 'Last name',
        width: '20%',
        rules: ['max:64'],
      },
      {
        key: 'user_first_name',
        displayName: 'First name',
        width: '20%',
        rules: ['max:64'],
      },
      {
        key: 'user_email',
        displayName: 'Email',
        width: '20%',
        rules: ['email', 'unique', 'max:128'],
        transformers: [trim, lowercase],
      },
      {
        key: 'user_external_id',
        displayName: 'External ID',
        width: '20%',
        rules: ['required', 'unique', 'max:64', 'exists:1.users,user_external_id'],
      },
      {
        key: 'user_advisor_in_office',
        displayName: 'Advisor in office',
        width: '20%',
      },
    ],
  },
  '6.contact per office': {
    displayName: 'Contact per office',
    fields: [
      {
        key: 'user_external_id',
        displayName: 'User external ID',
        width: '33.33%',
        rules: ['required', 'max:64', 'exists:1.users,user_external_id'],
      },
      {
        key: 'office_external_id',
        displayName: 'Office external ID',
        width: '33.33%',
        rules: ['required', 'max:64', 'exists:2.offices,office_external_id'],
      },
      {
        key: 'office_title',
        displayName: 'Office title',
        width: '33.33%',
      },
    ],
  },
};

export default skedifyExcelStructure;
