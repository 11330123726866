import errorHandler from '../utils/errorHandler';

function fetchExistingContactOffices(SDK) {
  return [
    (context) =>
      SDK.contactOffices()
        .then(({ data }) => {
          return data.map((contact_office) => {
            const employee = Object.values(context.existingContacts).find(
              (employee) => employee.roles.contact.id === contact_office.contact_id
            );
            const user_external_id = employee && employee.external_id;

            const office = Object.values(context.existingOffices).find(
              (office) => office.id === contact_office.office_id
            );
            const office_external_id = office && office.external_id;

            return {
              key: `${user_external_id}-${office_external_id}`,
              resource: contact_office,
            };
          });
        })
        .catch(errorHandler()),
  ];
}

export default fetchExistingContactOffices;
