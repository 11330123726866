import mapData from '../utils/mapData';
import generatePassword from '../../../utils/generatePassword';
import isExistingUser from '../utils/isExistingUser';
import errorHandler from '../utils/errorHandler';

const mapping = {
  email: 'user_email',
  last_name: 'user_last_name',
  first_name: 'user_first_name',
  language: 'user_language',
  phone_number: 'user_phone',
  password: () => generatePassword(64),
  timezone: () => 'Europe/Brussels',
  employee_activation: (context, { user_external_id }) => {
    const employee =
      (context.employees && context.employees[user_external_id]) ||
      (context.existingEmployees && context.existingEmployees[user_external_id]);

    return (
      employee &&
      context.employeeActivations &&
      context.employeeActivations[employee.id] &&
      context.employeeActivations[employee.id].token
    );
  },
};

function createUsers(SDK, data, key) {
  return data.map((user) => {
    return (context) => {
      const userData = mapData(context, user, mapping);

      if (isExistingUser(context, user.user_external_id)) {
        return Promise.resolve();
      }

      return SDK.users()
        .new(userData)
        .then((resource) => resource.create())
        .then((response) => ({
          key: user[key],
          resource: response.data,
        }))
        .catch(errorHandler(userData));
    };
  });
}
export default createUsers;
