import React, { useState, useMemo } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import useExcelReader from '../shared/hooks/useExcelReader';
import usePersistentState from '../shared/hooks/usePersistentState';

import Heading from './Heading';
import DataLoader from './DataLoader';
import ValidationColumn from './Columns/ValidationColumn';
import skedifyExcelStructure from '../skedifyExcelStructure';
import WorkbookValidator from '../utils/WorkbookValidator';

import { EXCEL_FILTER } from '../constants';
import Import from '../screens/Import';
import Excel from '../screens/Excel';
import Settings from '../screens/Settings';

import './App.css';
import createSDK from '../sdk';
import Center from '../shared/Center';
import Strong from '../shared/Strong';
import ChooseFile from './ChooseFile';
import LinkButton from '../shared/LinkButton';

function enhanceSheets(sheets) {
  const workbookValidator = new WorkbookValidator(sheets, skedifyExcelStructure);
  const validatedSheets = workbookValidator.validate();

  return Object.entries(skedifyExcelStructure).map(([key, { fields, displayName }]) => {
    let data = validatedSheets[key];

    const columns = [
      {
        key: 'validationErrors',
        displayName: '',
        width: '34px',
        render: ValidationColumn,
      },
      ...fields,
    ];

    return {
      key,
      displayName,
      columns,
      data,
    };
  });
}

function filterSheets(sheets, filter) {
  if (filter === EXCEL_FILTER.ALL) {
    return sheets;
  }

  return sheets.map((sheet) => {
    return {
      ...sheet,
      data: sheet.data.filter((row) => row.validationErrors),
    };
  });
}

function App() {
  const [file, setFile, sheets] = useExcelReader();
  const [filter, setFilter] = useState(EXCEL_FILTER.ONLY_ERRORS);
  const [credentials, setCredentials] = usePersistentState('credentials');

  const SDK = useMemo(() => credentials && createSDK(credentials), [credentials]);
  const enhancedSheets = useMemo(() => sheets && enhanceSheets(sheets), [sheets]);
  const filteredSheets = useMemo(() => enhancedSheets && filterSheets(enhancedSheets, filter), [
    enhancedSheets,
    filter,
  ]);

  function reset() {
    setFile(undefined);
  }

  function toggleFilter() {
    setFilter(filter === EXCEL_FILTER.ONLY_ERRORS ? EXCEL_FILTER.ALL : EXCEL_FILTER.ONLY_ERRORS);
  }

  return (
    <Router>
      <div className="App w-full font-sans text-black antialiased" path="/">
        <Heading toggleFilter={toggleFilter} filter={filter} />
        <div className="flex justify-center mt-6">
          <div className="w-5/6">
            {!file && <ChooseFile setFile={setFile} />}
            {file && (
              <Center>
                <span className="text-blue-800">
                  <Strong>{file.name}</Strong> ·{' '}
                  <LinkButton onClick={() => reset()}>reupload</LinkButton>
                </span>
              </Center>
            )}
            <DataLoader SDK={SDK} sheets={enhancedSheets}>
              {(resourceGroups, load, pause, resume, importAll) => (
                <Switch>
                  <Route path="/import">
                    <Import
                      file={file}
                      resourceGroups={resourceGroups}
                      load={load}
                      pause={pause}
                      resume={resume}
                      importAll={importAll}
                    />
                  </Route>
                  <Route path="/settings">
                    <Settings credentials={credentials} setCredentials={setCredentials} />
                  </Route>
                  <Route path="/">
                    <Excel file={file} reset={reset} filteredSheets={filteredSheets} />
                  </Route>
                </Switch>
              )}
            </DataLoader>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
