import errorHandler from '../utils/errorHandler';

function fetchExistingOffices(SDK) {
  return [
    () =>
      SDK.offices()
        .then(({ data }) =>
          data.map((office) => ({
            key: office.external_id,
            resource: office,
          }))
        )
        .catch(errorHandler()),
  ];
}

export default fetchExistingOffices;
