function mapData(context, data, mapping) {
  const mappedObject = {};

  Object.entries(mapping).forEach(([key, value]) => {
    if (typeof value === 'object') {
      mappedObject[key] = mapData(context, data, mapping[key]);
    } else if (typeof value === 'function') {
      mappedObject[key] = mapping[key](context, data);
    } else {
      mappedObject[key] = data[value];
    }
  });

  return mappedObject;
}

export default mapData;
